.features-page {
  min-height: 100vh;
  background: var(--background-light);
  padding-top: 80px;
}

/* Hero Section */
.features-hero {
  background: var(--hero-gradient);
  padding: var(--spacing-xl) var(--spacing-md);
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.features-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.6;
}

.features-hero h1 {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: var(--spacing-md);
  position: relative;
  z-index: 1;
}

.features-hero p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto var(--spacing-lg);
  opacity: 0.9;
  position: relative;
  z-index: 1;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-sm);
  background: white;
  color: var(--primary-color);
  padding: 1rem 2rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.arrow {
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.cta-button:hover .arrow {
  transform: translateX(4px);
}

/* Feature Sections */
.features-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-xl) var(--spacing-md);
}

.feature-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  align-items: center;
}

.feature-section.reversed {
  direction: rtl;
}

.feature-section.reversed .feature-content {
  direction: ltr;
}

.feature-content h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: var(--spacing-md);
  background: var(--hero-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description {
  font-size: 1.1rem;
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
  line-height: 1.6;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  font-size: 1.1rem;
}

.feature-bullet {
  color: var(--primary-color);
  font-size: 1.5em;
}

.feature-visual {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-card {
  background: white;
  border-radius: var(--border-radius);
  padding: var(--spacing-xl);
  box-shadow: var(--card-shadow);
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(98, 0, 234, 0.1), rgba(0, 191, 165, 0.1));
  opacity: 0.5;
}

.feature-icon {
  font-size: 2rem;
  position: relative;
  z-index: 1;
}

/* CTA Section */
.features-cta {
  background: var(--hero-gradient);
  color: white;
  text-align: center;
  padding: var(--spacing-xl) var(--spacing-md);
  margin-top: var(--spacing-xl);
}

.features-cta h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: var(--spacing-md);
}

.features-cta p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto var(--spacing-lg);
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: var(--spacing-md);
  justify-content: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-hero h1 {
    font-size: 2.5rem;
  }

  .feature-section {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }

  .feature-section.reversed {
    direction: ltr;
  }

  .feature-content h2 {
    font-size: 2rem;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }

  .feature-card {
    aspect-ratio: 3/2;
  }
} 