.note-content {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 5px !important;
    line-height: 1 !important;
    flex: 1;
    overflow-y: auto;
    margin-bottom: 8px;
    min-height: 100px;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;
    position: relative;
    z-index: 1;
}

.note-line {
    margin-bottom: 8px;
}

/* Checkbox Items */
.checkbox-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 100%;
  list-style-type: none; 
}

.checkbox {
    margin-right: 8px !important;
    margin-top: 0 !important;
    flex-shrink: 0 !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #4f46e5 !important;
    border-radius: 4px !important;
    background-color: white !important;
    position: relative !important;
    cursor: pointer !important;
    display: inline-block !important;
    vertical-align: middle !important;
    box-sizing: border-box !important;
}

.checkbox:checked {
    background-color: #4f46e5 !important;
    border-color: #4f46e5 !important;
}

.checkbox:checked::after {
    content: none !important;
}

.edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.checkbox-label {
    flex: 1;
    display: inline;
    width: auto;
    vertical-align: middle;
}

.checkbox-label.checked {
    text-decoration: line-through;
    color: #888;
}

/* Remove any Daisy UI specific classes */
.note-content > div {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 25px; /* Increased height for better touch accessibility */
  cursor: se-resize;
  background-color: transparent; 
  z-index: 3; /* Ensure it's above the drag handle */
  background-image: linear-gradient(135deg, #ccc 25%, transparent 25%), 
                    linear-gradient(225deg, #ccc 25%, transparent 25%),
                    linear-gradient(45deg, #ccc 25%, transparent 25%), 
                    linear-gradient(315deg, #ccc 25%, transparent 25%);
  background-position: 5px 5px, 5px 5px, 5px 5px, 5px 5px;
  background-size: 5px 5px;
  background-repeat: no-repeat;
}

.note {
  background-color: #ffffff;
  border: 1px solid rgba(226, 232, 240, 0.8);
  border-radius: 16px;
  padding: 20px !important;
  box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.08),
              0 4px 8px -2px rgba(0, 0, 0, 0.06),
              0 0 2px rgba(0, 0, 0, 0.04);
  overflow: auto;
  min-width: 300px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 1.5em !important;
  /* backdrop-filter: blur(12px);
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)    
  ); */
  border-left: 4px solid #4f46e5;
  padding-bottom: 60px !important;
  height: 100%;
}

.note-highlight {
  position: absolute;
  background-color: rgba(173, 216, 230, 0.5); /* Light blue with 50% opacity */
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 2; 

}
.note-content-wrapper {
  position: relative;
  z-index: 1;
  padding: 5px !important;
  margin-top: 25px !important;
}


.note-highlight.active {
  opacity: 1;
}

.note-highlight-top {
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
}

.note-highlight-left {
  top: 50px;
  left: 0;
  bottom: 0;
  width: 30px;
}

.note-highlight-right {
  top: 50px;
  right: 0;
  bottom: 0;
  width: 12px;
}

.note-highlight-bottom {
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 30px;
}

.resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 15px;
  cursor: se-resize;
  background-color: #ccc;
  border-top-left-radius: 15px;
}

.edit-button {
  margin-top: auto;
  align-self: flex-start;
}

/* Increase specificity for Markdown styles */
.note-content h1,
.note-content h2,
.note-content h3,
.note-content h4,
.note-content h5,
.note-content h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
}

.note-content h1 { font-size: 2em; }
.note-content h2 { font-size: 1.5em; }
.note-content h3 { font-size: 1.17em; }
.note-content h4 { font-size: 1em; }
.note-content h5 { font-size: 0.83em; }
.note-content h6 { font-size: 0.67em; }

.note-content p {
    margin-bottom: 0.5em !important;
}

.note-content ul {
  list-style-type: disc; /* Display bullets */
  padding-left: 1.5em !important; /* Reduce left padding */
  margin-bottom: 0.5em !important; /* Reduce bottom margin */
  list-style-position: outside !important;
}
.note-content ol {
  list-style-type: decimal; /* Display numbers */
  padding-left: 1.5em !important; /* Reduce left padding */
  margin-bottom: 0.5em !important; /* Reduce bottom margin */
  list-style-position: outside !important;
}

/* List Items */
.note-content li {
  margin-bottom: 0.25em !important; /* Reduce space between items */
  padding-left: 0.5em !important; /* Add small padding for text */
  display: list-item !important; /* Ensure proper list display */
  list-style-type: inherit; /* Inherit list style from parent */
  list-style-position: outside;
}

.note-content a {
    color: #0000FF;
    text-decoration: underline;
}

/* Increase specificity for code blocks */
.note-content pre,
.note-content pre code {
  display: block;
  background-color: #f0f0f0;
  padding: 1     em;
  overflow-x: auto;
  margin-bottom: 1em;
  white-space: pre-wrap;
  word-break: break-all;
}

.note-content code {
  background-color: #f0f0f0;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

/* Override any conflicting styles */
.note-content pre,
.note-content pre code,
.note-content code {
  font-size: 0.9em !important;
  line-height: 1 !important;
  color: #333 !important;
}

.bottom-drag-handle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20px; /* Adjust the height as needed */
  cursor: grab;
  background-color: transparent;
}

.note-edit-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* Edit Form Header */
.edit-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.title-edit-input {
  flex: 1;
  padding: 8px;
  font-size: 1.1rem;
  border: 2px solid #4f46e5; /* Indigo-600 */
  border-radius: 5px;
  margin-right: 10px;
  margin-right: 0;
  margin-bottom: 10px;
}

.edit-save-button,
.edit-cancel-button {
  padding: 8px 16px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.edit-save-button {
  background-color: #4f46e5; /* Indigo-600 */
  color: white;
  margin-right: 5px;
}

.edit-save-button:hover {
  background-color: #4338ca; /* Indigo-700 */
}

.edit-cancel-button {
  background-color: #e5e7eb; /* Gray-200 */
  color: #333;
}

.edit-cancel-button:hover {
  background-color: #d1d5db; /* Gray-300 */
}

/* Edit Form Body */
.edit-form-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Tags Edit Input */
.tag-edit-input {
  margin-bottom: 10px;
}

.tags-edit-input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 2px solid #4f46e5; /* Indigo-600 */
  border-radius: 5px;
  margin-bottom: 5px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
}

.tag-edit {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  background-color: #bfdbfe; /* Blue-300 */
  border-radius: 15px;
  font-size: 0.9rem;
}

.delete-tag-edit {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  color: #ef4444; /* Red-500 */
}

/* Edit Textarea */
.note-edit-textarea {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #4f46e5; /* Indigo-600 */
  border-radius: 5px;
  resize: vertical;
  min-height: 150px;
  margin-bottom: 10px;
  outline: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
} 

.note-edit-textarea:focus {
  border-color: #4338ca; /* Indigo-700 */
}
/* Edit Form Footer */
.edit-form-footer {
  display: flex;
  flex-direction: column;
}

.privacy-options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.privacy-options label {
  margin-right: 15px;
  font-size: 0.9rem;
  cursor: pointer;
  width: 100%;
  margin-bottom: 5px;
}

.organization-edit-input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 2px solid #4f46e5; /* Indigo-600 */
  border-radius: 5px;
  margin-top: 5px;
}

/* Buttons in Edit Form */
.edit-save-button,
.edit-cancel-button {
  height: 40px;
}

/* Adjust the Markdown editor within the note */
.note-edit-textarea {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .note-edit-form {
      padding: 10px;
  }

  .edit-form-header {
      flex-direction: column;
      align-items: stretch;
  }


  .edit-save-button,
  .edit-cancel-button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5px;
  } 


}
/* Header Buttons Container */
.note-header-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row; /* Horizontal alignment */
  gap: 15px; /* Spacing between buttons */
  align-items: center; /* Vertical alignment */
  z-index: 10;
}

/* Maximize Button */
.maximize-button,
.delete-note {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  right: 45px;
  top: -.5px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.maximize-button {
  background-color: #4f46e5; /* Indigo background */
  color: white;
}

.maximize-button:hover {
  background-color: #3730a3; /* Darker indigo on hover */
}

.delete-note {
  background-color: #ef4444; /* Red background */
  color: white;
}

.delete-note:hover {
  background-color: #dc2626; /* Darker red on hover */
}

/* Maximized Note Styling */
.note.maximized {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  max-width: 97%;
  width: 90%;
  height: 85%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px;
  padding: 20px;
  overflow: hidden !important;
  background-color: white;
}

/* Specific styles for maximized note content */
.maximized .note-content {
  /* height: calc(100% - 120px) !important; Reduced from 200px to 120px */
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-right: 16px !important;
  margin-right: -16px !important;
  margin-bottom: 0px !important; /* Add space for the edit button */
}

/* Ensure edit button stays at bottom in maximized view */
.maximized .edit-note {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

/* Adjust title spacing in maximized view */
.maximized .note-title {
  margin-bottom: 12px;
}

/* Adjust tags spacing in maximized view */
.maximized .note-tags {
  margin: 8px 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.maximized-content::-webkit-scrollbar {
  width: 8px;
}

.maximized-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.maximized-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.maximized-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Add transition for transform and size changes */
.note {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .note-header-buttons {
    gap: 10px; /* Reduce spacing on smaller screens */
  }

  .maximize-button,
  .delete-note {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
}

/* Base checkbox styles */
.checkbox-item {
  display: flex;
  align-items: center;
  margin: 2px 0;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 100%;
  list-style-type: none; 
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .checkbox-item {
    padding-left: 35px !important;
    padding-right: 15px !important;
  }

  .checkbox {
    margin-right: 12px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    transform: scale(1.1) !important;
  }

  .note-content {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

/* List containers */
.note-content ul,
.note-content ol {
    padding-left: 1.5em !important;
    margin: 0 !important;
    list-style-position: outside !important;
}

/* List items */
.note-content li {
    margin: 0 !important; /* Remove margins between items */
    padding: 0 0 0 0.3em !important; /* Minimal left padding */
    display: list-item !important;
}

/* Numbered list specific */
.note-content ol {
    list-style: none !important; /* Remove default numbering */
    counter-reset: item !important;
}

.note-content ol > li {
    counter-increment: item !important;
    position: relative !important;
}


/* Bullet list specific */
.note-content ul {
    list-style-type: disc !important;
    margin-left: 0 !important;
}

.note-content ul > li {
    padding-left: 0.1em !important;
}

.note-content ol > li::before {
    content: counter(item) "." !important;
    left: -1.1em !important;
    
    width: 1em !important;
    text-align: right !important;
    /* display: inline-block !important; */
}

/* Nested lists */
.note-content ul ul,
.note-content ol ol,
.note-content ul ol,
.note-content ol ul {
    margin: 0 !important;
    padding-left: 1.5em !important;
} 

/* Line spacing for text content */
.note-content p {
    margin: 0 !important;
    padding: 0 !important;
}


.note-content-wrapper {
    padding: 2px !important;
    margin-top: 20px !important;
}

/* Remove any extra spacing from divs */
.note-content > div {
    margin: 0 !important;
    padding: 0 !important;
}

/* Title styling */
.note-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f0f0;
  letter-spacing: -0.02em;
  line-height: 1.3;
}

/* Tags container */
.note-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0;
  padding: 0;
}

/* Individual tag styling */
.note-tags span {
  background-color: #f3f4f6;
  color: #4f46e5;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s ease;
  border: 1px solid #e5e7eb;
  display: inline-flex;
  align-items: center;
}

.note-tags span:hover {
  background-color: #e0e7ff;
  transform: translateY(-1px);
}

/* Visibility badge styling */
.note-visibility {
  display: inline-flex;
  align-items: center;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 0.85rem;
  color: #64748b;
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 500;
}

/* Add icons for different visibility types */
.note-visibility::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
}               

.note-visibility[data-visibility="Global"]::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'%3E%3C/path%3E%3C/svg%3E");
}

.note-visibility[data-visibility="Private"]::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z'%3E%3C/path%3E%3C/svg%3E");
}

.note-visibility[data-visibility="Organization"]::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%2364748b'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4'%3E%3C/path%3E%3C/svg%3E");
}

/* Position edit button at bottom */
.edit-note {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  background-color: #4f46e5;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
}

/* Ensure content doesn't overlap with button */
.note-content {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 8px;
  min-height: 100px; /* Ensure minimum content height */
}

