.side-panel {
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 300px;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;
    transition: transform 0.3s ease;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    transform: translateX(-100%);
}

.side-panel.open {
    transform: translateX(0);
}

.panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 10;
}

.toggle-button {
    background-color: #2563eb;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 15rem;
    top: 1rem;
    z-index: 10;
}

.toggle-button:hover {
    background-color: #1d4ed8;
}

.notes-list {
    padding: 1rem;
    position: relative;
    z-index: 1;
    margin-top: 0;
}

.note-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s;
    position: relative;
    z-index: 1;
}

.note-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.note-title {
    margin: 0 0 0.5rem 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: #2c3e50;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.tag {
    background-color: #e9ecef;
    padding: 0.2rem 0.5rem;
    border-radius: 12px;
    font-size: 0.8rem;
    color: #495057;
}

.note-preview {
    font-size: 0.9rem;
    color: #6c757d;
    margin: 0;
    line-height: 1.4;
}

.error-message {
    color: #dc3545;
    padding: 1rem;
    text-align: center;
}

.no-notes {
    color: #6c757d;
    padding: 1rem;
    text-align: center;
}

.note-meta {
    font-size: 0.8rem;
    color: #6c757d;
    margin-top: 0.5rem;
}

.note-date {
    display: block;
}

.expand-button {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #2563eb;
    color: white;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    transition: all 0.3s ease;
    width: 1.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.expand-button:hover {
    background-color: #1d4ed8;
    width: 2rem;
}

.note-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.action-button {
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
    color: white;
}

.notebook-button {
    background-color: #2563eb;
}

.notebook-button:hover {
    background-color: #1d4ed8;
}

.goto-button {
    background-color: #10b981;
}

.goto-button:hover {
    background-color: #059669;
}
