:root {
  --primary-color: #6200EA;
  --primary-light: #B388FF;
  --primary-dark: #4A148C;
  --secondary-color: #00BFA5;
  --text-primary: #1A1A1A;
  --text-secondary: #666666;
  --background-light: #FFFFFF;
  --background-dark: #F5F5F5;
  --success-color: #00C853;
  --error-color: #FF1744;
  --border-radius: 12px;
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --spacing-xl: 4rem;
  --hero-gradient: linear-gradient(135deg, #6200EA 0%, #B388FF 100%);
  --card-shadow: 0 8px 32px rgba(98, 0, 234, 0.1);
}

.landing-page {
  min-height: 100vh;
  background: var(--background-light);
  color: var(--text-primary);
}

/* Navigation */
.landing-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1000;
  padding: var(--spacing-md);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: var(--text-primary);
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: var(--primary-color);
}

.nav-cta {
  background: var(--primary-color);
  color: white !important;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  transition: background-color 0.3s ease !important;
}

.nav-cta:hover {
  background: var(--primary-dark);
}

/* Hero Section */
.hero-section {
  background: var(--hero-gradient);
  padding-top: 120px;
  padding-bottom: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.6;
}

.hero-content {
  text-align: center;
  margin-bottom: var(--spacing-xl);
  position: relative;
  z-index: 1;
  padding: 0 var(--spacing-md);
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 900;
  margin-bottom: var(--spacing-lg);
  line-height: 1.1;
  color: white;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.hero-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: var(--border-radius);
  padding: var(--spacing-xl);
  margin: 0 auto;
  max-width: 800px;
  box-shadow: var(--card-shadow);
}

.gradient-text {
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  text-shadow: none;
  position: relative;
  display: inline-block;
}

.gradient-text::after {
  content: 'Notes';
  position: absolute;
  left: 0;
  top: 0;
  background: none;
  -webkit-text-fill-color: white;
  opacity: 0.9;
  z-index: -1;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.hero-description {
  font-size: 1.25rem;
  color: var(--text-primary);
  max-width: 600px;
  margin: 0 auto var(--spacing-lg);
}

.hero-actions {
  display: flex;
  gap: var(--spacing-md);
  justify-content: center;
}

.primary-button, .secondary-button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-sm);
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.primary-button {
  background: var(--primary-color);
  color: white;
}

.primary-button:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.secondary-button {
  background: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.secondary-button:hover {
  background: rgba(98, 0, 234, 0.1);
  transform: translateY(-2px);
}

.button-icon {
  font-size: 1.1em;
}

/* Stats Section */
.hero-stats {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-xl);
  background: white;
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
  max-width: 900px;
  margin: var(--spacing-xl) auto 0;
}

.stat-item {
  text-align: center;
  padding: var(--spacing-md);
}

.stat-number {
  display: block;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: var(--spacing-xs);
}

.stat-label {
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

/* Pricing Section */
.pricing-section {
  padding: var(--spacing-xl) var(--spacing-md);
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: var(--spacing-xl);
  font-weight: 700;
}

.pricing-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  max-width: 1000px;
  margin: 0 auto;
}

.pricing-card {
  background: white;
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.pricing-card.popular {
  border: 2px solid var(--primary-color);
}

.popular-badge {
  position: absolute;
  top: 1rem;
  right: -2rem;
  background: var(--primary-color);
  color: white;
  padding: 0.25rem 2rem;
  transform: rotate(45deg);
  font-size: 0.875rem;
}

.price-tag {
  text-align: center;
  margin: var(--spacing-md) 0;
}

.currency {
  font-size: 1.5rem;
  vertical-align: top;
}

.amount {
  font-size: 3rem;
  font-weight: 700;
}

.period {
  color: var(--text-secondary);
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: var(--spacing-lg) 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.feature-check {
  color: var(--success-color);
  font-weight: bold;
}

.pricing-cta {
  display: block;
  text-align: center;
  padding: 0.75rem;
  border-radius: var(--border-radius);
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.pricing-cta.primary {
  background: var(--primary-color);
  color: white;
}

.pricing-cta.secondary {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

/* Features Section */
.features-section {
  padding: var(--spacing-lg) var(--spacing-md);
  max-width: 1200px;
  margin: 0 auto;
  background: var(--background-light);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.feature-card {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-height: 140px;
}

.feature-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.feature-icon-wrapper {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.feature-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-card h3 {
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
}

.feature-card p {
  color: var(--text-secondary);
  line-height: 1.3;
  font-size: 0.85rem;
  margin: 0;
  max-width: 200px;
}

/* Section title styling */
.section-title {
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: var(--spacing-lg);
  font-weight: 700;
  color: var(--text-primary);
  position: relative;
}

/* Add margin to the explore features button container */
.features-section .text-center {
  margin-top: var(--spacing-lg);
}

/* Responsive Features Grid */
@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
  
  .feature-card {
    min-height: 120px;
  }
  
  .feature-icon-wrapper {
    font-size: 1.5rem;
  }
  
  .feature-card h3 {
    font-size: 0.95rem;
  }
  
  .feature-card p {
    font-size: 0.8rem;
  }
}

/* Footer */
.landing-footer {
  background: white;
  padding: var(--spacing-xl) var(--spacing-md);
  margin-top: var(--spacing-xl);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-cta {
  text-align: center;
  max-width: 600px;
  margin: 0 auto var(--spacing-xl);
}

.footer-cta h2 {
  font-size: 2rem;
  margin-bottom: var(--spacing-md);
}

.footer-cta p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--spacing-lg);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-links {
  display: flex;
  gap: var(--spacing-md);
}

.footer-links a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .hero-stats {
    flex-direction: column;
    gap: var(--spacing-lg);
  }
  
  .hero-actions {
    flex-direction: column;
  }
  
  .footer-info {
    flex-direction: column;
    gap: var(--spacing-md);
    text-align: center;
  }
}

/* Update responsive design for hero section */
@media (max-width: 768px) {
  .hero-section {
    padding-top: 100px;
    padding-bottom: var(--spacing-lg);
  }

  .hero-content {
    padding: 0 var(--spacing-sm);
  }

  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: var(--spacing-md);
  }

  .hero-card {
    padding: var(--spacing-lg);
    margin: 0 var(--spacing-sm);
  }

  .hero-description {
    font-size: 1.1rem;
    margin-bottom: var(--spacing-md);
  }

  .hero-actions {
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  .primary-button, .secondary-button {
    width: 100%;
    justify-content: center;
  }

  .hero-stats {
    flex-direction: column;
    padding: var(--spacing-md);
    margin: var(--spacing-lg) var(--spacing-sm) 0;
  }

  .stat-item {
    padding: var(--spacing-sm);
  }

  .stat-number {
    font-size: 2rem;
  }

  .stat-label {
    font-size: 1rem;
  }
}