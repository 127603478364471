.gpt-options-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  -webkit-overflow-scrolling: touch;
}

.gpt-options-window {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.gpt-options-window h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.gpt-options-window textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  resize: vertical;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button-group button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.cancel-button {
  background-color: #e5e7eb;
}

.submit-button {
  background-color: #4f46e5;
  color: white;
}

.submit-button:disabled {
  background-color: #a5a5a5;
  cursor: not-allowed;
}

.error-message {
  color: #dc2626;
  background-color: #fee2e2;
  padding: 8px;
  margin: 8px 0;
  border-radius: 4px;
  font-size: 14px;
}

.preview-content {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-word;
}

@media (max-width: 768px) {
  .gpt-options-window {
    width: 95%;
    padding: 15px;
    margin: 10px;
    max-height: 70vh;
  }

  .gpt-options-window textarea {
    font-size: 16px !important;
    padding: 8px;
  }

  .preview-content {
    max-height: 150px;
    font-size: 14px;
  }

  .button-group button {
    min-height: 44px;
    font-size: 16px;
  }
}
