/* OptionsWindow.css */

/* Visually hidden class for accessibility */
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
  }
  
  .options-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent; /* Semi-transparent background if desired */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .options-window {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    min-width: 200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
  }
  
  .option-item {
    padding: 12px 16px;
    margin: 4px 0;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    font-size: 16px;
  }
  
  .option-item:hover,
  .option-item:focus {
    background-color: #f0f0f0;
    outline: none;
  }
  
  @media (max-width: 600px) {
    .options-window {
      width: 90%;
    }
    
    .option-item {
      font-size: 18px;
      padding: 16px 20px;
    }
  }
  