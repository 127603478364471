/* RopeOptions.css */

.rope-options-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .rope-options-window {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .rope-option-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  .rope-option-item:last-child {
    border-bottom: none;
  }
  
  .rope-option-item:hover {
    background-color: #f5f5f5;
  }
  
  #rope-options-title {
    margin-bottom: 15px;
    font-size: 1.2em;
  }
  
  .rope-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Allow clicks through the SVG */
    overflow: visible;
    z-index: 1;
  }