/* NoteForm.css */

/* Container for the toolbar */
.note-form-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

/* Collapsed state */
.note-form-container.collapsed {
    height: 50px;
}

/* Expanded state - Larger for desktop */
.note-form-container.expanded {
    height: 90vh;
    max-height: calc(100vh - 20px);
}

/* Toolbar header */
.toolbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    min-height: 50px;
    flex-shrink: 0;
    background-color: #4f46e5;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.toolbar-header:hover {
    background-color: #4338ca;
}

.toolbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.toolbar-title {
    font-size: 1.2rem;
    font-weight: bold;
    user-select: none;
}

.note-form-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    pointer-events: none;
}

.note-form-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

/* Form styling */
.note-form {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: calc(100% - 50px);
}

/* Ensure inputs take full width */
.title-input {
    width: 100%;
    margin-bottom: 8px;
}

.tag-input {
    margin-bottom: 10px;
}

.tag-input input {
    width: 100%;
    padding: 6px;
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    display: flex;
    align-items: center;
    padding: 3px 8px;
    margin: 3px;
    background-color: #bfdbfe;
    border-radius: 15px;
    font-size: 0.8rem;
}

.delete-tag {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
}

.relative {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    height: calc(100vh - 300px);
}

.recommendations {
    max-height: 200px;
    overflow-y: auto;
    margin: 15px 0;
}

.content-type {
    margin-bottom: 10px;
}

.slider-container {
    display: flex;
    position: relative;
    background-color: #e5e7eb;
    border-radius: 25px;
    overflow: hidden;
    margin-bottom: 8px;
}

.slider-option {
    flex: 1;
    text-align: center;
    padding: 8px 0;
    cursor: pointer;
    z-index: 1;
    position: relative;
    font-size: 0.9rem;
}

.slider-option.active {
    color: white;
    font-weight: bold;
}

.slider-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 33.3333%;
    height: 100%;
    background-color: #4f46e5;
    border-radius: 25px;
    transition: transform 0.3s ease-in-out;
    z-index: 0;
}

.slider-label {
    position: relative;
    z-index: 2;
}

.organization-input {
    width: 100%;
    padding: 8px;
    border: 2px solid #4f46e5;
    border-radius: 5px;
    padding: 6px;
}

.note-button {
    width: 100%;
    padding: 12px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease-in-out;
    margin-top: 8px;
}

.note-button:hover {
    background-color: #4338ca;
}

/* Adjust the Markdown editor */
.react-md-editor {
    flex: 1 !important;
    min-height: 300px !important;
    display: flex !important;
    flex-direction: column !important;
}

.rc-md-editor {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
    min-height: 300px !important;
    height: 100% !important;
}

/* The actual editable area */
.rc-md-editor .section-container {
    flex: 1 !important;
    overflow-y: auto !important;
    min-height: 300px !important;
    height: calc(100% - 40px) !important;
}

.rc-md-editor textarea {
    height: 100% !important;
    min-height: 300px !important;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .note-form-container.expanded {
        height: 85vh;
        display: flex;
        flex-direction: column;
    }

    .note-form {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    /* Editor container */
    .relative {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0; /* Critical for nested flex scrolling */
    }

    /* Markdown editor specific styles */
    .react-md-editor {
        flex: 1 !important;
        min-height: 0 !important; /* Critical for nested flex scrolling */
        display: flex !important;
        flex-direction: column !important;
    }

    .rc-md-editor {
        flex: 1 !important;
        display: flex !important;
        flex-direction: column !important;
        overflow: hidden !important;
    }

    /* The actual editable area */
    .rc-md-editor .section-container {
        flex: 1 !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch !important;
        touch-action: pan-y !important;
    }

    .rc-md-editor textarea {
        height: 100% !important;
        touch-action: pan-y !important;
        -webkit-overflow-scrolling: touch !important;
        font-size: 16px !important; /* Prevent zoom on iOS */
    }

    /* Recommendations area */
    .recommendations {
        margin-top: 10px;
        max-height: 150px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    /* Ensure buttons are easily tappable */
    .note-button {
        min-height: 44px;
        margin: 10px 0;
    }
}

/* Ensure proper touch handling for all scrollable areas */
.note-form,
.rc-md-editor,
.rc-md-editor textarea,
.recommendations {
    touch-action: pan-y;
    -webkit-overflow-scrolling: touch;
}

/* GPT Options styling */
.gpt-options-window {
    max-height: 80vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* Fix for iOS momentum scrolling */
.note-form,
.recommendations,
.gpt-options-window {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* Fix: Ensure proper event handling in the editor area */
.rc-md-editor {
    pointer-events: auto !important;
    touch-action: auto !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
}

.rc-md-editor .section-container {
    flex: 1 !important;
    touch-action: pan-y pinch-zoom !important;
    -webkit-overflow-scrolling: touch !important;
}

.rc-md-editor textarea {
    pointer-events: auto !important;
    touch-action: manipulation !important;
    -webkit-user-select: text !important;
    user-select: text !important;
    font-size: 16px !important;
}

/* Remove any pointer-events: none that might be inherited */
.note-form,
.note-form * {
    pointer-events: auto;
}

/* Ensure overlays don't interfere with editor */
.gpt-options-overlay,
.options-overlay {
    pointer-events: none;
}

.gpt-options-window,
.options-window {
    pointer-events: auto;
}
